import request from "../../utils/request"

// 设备信息
export function device(sousuo, page, pagesize) {
    const url = `/api/device?sousuo=${sousuo}&page=${page}&pagesize=${pagesize}`;
    return request.get(url);
}

// 所有企业信息接口
export function deviceCreate() {
    const url = `/api/device/create`;
    return request.get(url);
}
//新增设备接口
export function deviceStore(device, device_number, qiye_id, address, content, lat, lng) {
    const url = `/api/device/store`;
    return request.post(url, {
        device,
        device_number,
        qiye_id,
        address,
        content,
        lat,
        lng
    });
}

// 编辑界面接口
export function deviceUpdate(id, device, device_number, qiye_id, address, content, lat, lng) {
    const url = `/api/device/update`;
    return request.post(url, {
        id,
        device,
        device_number,
        qiye_id,
        address,
        content,
        lat,
        lng
    });
}
// 删除设备接口
export function deviceDel(ids) {
    const url = `/api/device/del`;
    return request.post(url, {
        ids
    });
}


// 泵余量修改
export function deviceEdit(data) {
    const url = `/api/deviceEdit`;
    return request.post(url, {
        data

    });
}

// 使用时间设置
export function usagetime(data) {
    const url = `/api/usagetime`;
    return request.post(url, {
        data

    });
}

// 常用设置修改
export function changyong(data) {
    const url = `/api/changyong`;
    return request.post(url, {
        data

    });
}

// 常用设置修改
export function dingshi(data) {
    const url = `/api/dingshi`;
    return request.post(url, {
        data
    });
}



// 常用设置修改
export function chushi(data) {
    const url = `/api/chushi`;
    return request.post(url, {
        data
    });
}

// 常用设置修改
export function setuperror(data) {
    const url = `/api/setuperror`;
    return request.post(url, {
        data
    });
}

// 重启
export function restart(sn) {
    const url = `/api/restart`;
    return request.post(url, {
        sn
    });
}