<template>
  <!-- 用户管理 -->
  <div class="equip">
    <div class="usertop">
      <p>设备管理</p>
      <div class="equipright">
        <p>
          共
          <span>{{ total }}</span>
          台
        </p>
        <p>累计运行 <span>{{ yunxing }}</span>次</p>
      </div>
    </div>
    <div class="usermain">
      <div class="userbtn">
        <p>设备状态：</p>
        <el-input
          v-model="userphone"
          placeholder="请输入设备名称或编号"
          style="width: 300px; margin-left: 15px"
          @keyup.enter.native="chaxun"
        ></el-input>
        <el-button type="primary" style="margin-left: 15px" @click="chaxun">
          查询
        </el-button>
      </div>
      <div class="userlist">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column label="设备" show-overflow-tooltip>
            <template slot-scope="scope">
              <p>{{ scope.row.device }}</p>
              <p>{{ scope.row.device_number }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="位置" show-overflow-tooltip>
          </el-table-column>
          <el-table-column label="设备运行" show-overflow-tooltip>
            <template slot-scope="scope">
              <p
                @click="toview(scope.$index, scope.row)"
                style="color: #5c98f7; cursor: pointer"
              >
                查看详情
              </p>
            </template>
          </el-table-column>
          <el-table-column label="在线状态" show-overflow-tooltip>
            <template slot-scope="scope">
              <p v-show="scope.row.status == 1" style="color: #f67777">异常</p>
              <p v-show="scope.row.status == 0" style="color: #7fcc5a">正常</p>
            </template>
          </el-table-column>
          <el-table-column prop="message" label="设备状态" min-width="40%" show-overflow-tooltip>
            <template slot-scope="scope">
              <p v-show="scope.row.error == 0" style="color: #f67777">异常</p>
              <p v-show="scope.row.error == 1" style="color: #7fcc5a">正常</p>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" show-overflow-tooltip>
            <template slot-scope="scope">
              <p>{{ scope.row.created_at }}</p>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="userpagging">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage1"
            :page-sizes="[10, 15, 20]"
            :page-size="10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { device } from "../../api/equip/device.js";
export default {
  components: {},
  props: {},
  data() {
    return {
      yunxing:null,
      userphone: "",
      options: [
        {
          value: "0",
          label: "全部",
        },
        {
          value: "1",
          label: "启用",
        },
        {
          value: "2",
          label: "禁用",
        },
      ],
      value: "",
      tableData: [],
      // 这个是控制选择框的参数    
      multipleSelection: [],
      currentPage1: 1,
      value2: true,
      total: 0,
      // 搜索后             
      sousuo: "",
       //                 
      pagesize: 10,
    };
  },
  created() {
    device("", 1, 10).then((res) => {
                    console.log(res.data)                    
      var str = res.data;
      this.yunxing = res.data.functionnumber
      if (str) {
        this.total = str.total;
        this.tableData = str.data;
      }
    });
  },
  mounted() {},
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    active_text(value, row) {
      console.log(value, row);
    },
    handleSizeChange(val) {
      this.pagesize = val;
      this.currentPage1 = 1;
      this.carelist(this.userphone, 1, this.pagesize);
    },
    handleCurrentChange(val) {
      this.currentPage1 = val;
      this.carelist(this.userphone, this.currentPage1, this.pagesize);
    },
    handleClose(done) {
      done();
    },
    // 查询                
    chaxun() {
      this.currentPage1 = 1;
      this.sousuo = JSON.parse(JSON.stringify(this.userphone));
      this.carelist(this.userphone, 1, this.pagesize);
    },
    // 数据列表展示

    
    carelist(sousuo, page, pagesize) {
      device(sousuo, page, pagesize).then((res) => {
        console.log(res);
        var str = res.data;
        if (str) {
          this.total = str.total;
          this.tableData = str.data;
        }
      });
    },
    // 查看详情
    toview(index, row) {
      console.log(row)
      console.log(index);
      this.$store.commit("savesheid", row.id);
      // this.$store.state.sheid = row.id      
      console.log(row.id);
      console.log("查看详情");
      this.$router.push("/userdetail");
      // this.$router.push({                
      //   path: "/userdetail",                   
      //   query: { detailmessage: JSON.stringify(row.id), role: 1 },     
      // });                                           
    },
  },
};
</script>

<style  lang='less' scoped>
.equip {
  padding: 20px;
  .usertop {
    height: 67px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    font-size: 24px;
    margin-bottom: 20px;
    .equipright {
      display: flex;
      p {
        margin-left: 25px;
      }
    }
  }
  .usermain {
    min-height: 760px;
    background: white;
    padding: 35px;
    padding-bottom: 15px;
    .userbtn {
      display: flex;
      align-items: center;
      font-size: 22px;
      /deep/.el-button--success {
        font-size: 20px;
      }
      /deep/.el-button--danger {
        font-size: 20px;
      }
      /deep/.el-input__inner {
        font-size: 18px;
      }
      /deep/.el-select-dropdown__item {
        font-size: 20px;
      }
      /deep/.el-button--primary {
        font-size: 18px;
      }
      p {
        margin-left: 15px;
      }
    }
    .userlist {
      .selbtn {
        display: flex;
        p {
          margin-right: 10px;
          color: #5c89f4;
          cursor: pointer;
        }
      }
    }
    .userpagging {
      margin-top: 20px;
    }
  }
  /deep/.el-table th,
  .el-table tr {
    font-size: 20px !important;
    height: 70px;
    background: #f2f2f2;
  }
  /deep/.el-table__body,
  .el-table__footer,
  .el-table__header {
    font-size: 20px !important;
  }
  /deep/.el-switch__label * {
    font-size: 16px;
  }
  /deep/.el-pagination__total {
    font-size: 18px !important;
  }
  /deep/.el-pager li {
    font-size: 17px;
  }
  /deep/.el-pagination__jump {
    font-size: 20px !important;
  }
  /deep/.el-input__inner {
    font-size: 17px;
  }
  /deep/.el-input--suffix .el-input__inner {
    font-size: 18px !important;
  }
  /deep/.el-dialog__title {
    font-size: 24px;
  }
  /deep/.el-dialog__body {
    font-size: 20px !important;
  }
}
</style>
